import { GridColumn } from '@progress/kendo-react-grid'
import { DropdownComponent } from 'components/dropdown/component'
import { FeatureUnavailableComponent } from 'components/feature-unavailable'
import * as Flash from 'components/flash'
import { DateTimeCell } from 'components/gridcells/datetime'
import { HeaderComponent } from 'components/header/component'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { format } from 'date-fns'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { BaseClientEntity } from 'generic/baseClientEntity'
import { Dataset } from 'generic/dataset'
import { EditableDropdownCell, EditCellProps} from 'generic/editCell'
import { RasaDataGrid } from 'generic/rasaDataGrid'
import * as GenericUtils from 'generic/utility'
import * as GA from 'google-analytics'
import { isEmpty, isNil, shuffle } from 'lodash'
import * as React from 'react'
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { FileSelector } from 'shared/components'
import { Roles } from 'shared/constants'
import { EmailAttributes } from 'shared_server_client/constants'
import { BillingPlanDetailCode } from 'shared_server_client/types/billing_plan'
import { refreshCommunityConfigurations } from 'shared/data-layer/community_configuration'
import { PublishedCell } from './published_cell'

import { loadSampleArticles } from '../../../shared/utils';
import './_styles.scss'
import { BRAND_NAME } from '../../../constants'

declare const DASHBOARD_ENV: string
declare const RASA_IMAGE_API_ENDPOINT: string

interface ModalState {
  visible: boolean,
}

interface ModalsState {
  download: ModalState,
  preview: ModalState,
  template: ModalState,
}

interface EmailTemplateState {
  communityId: string,
  documentation: any,
  emailLayoutId: number,
  emailTemplateId: number,
  emailAddress: string,
  fileId: string,
  fileName: string,
  forceReload: boolean,
  hasFeatureAccess: boolean,
  htmlPreview?: string,
  issues: any[],
  isIssuesLoading: boolean,
  isLoading: boolean,
  isLoadingPreview: boolean,
  isSuperUser: boolean,
  publishedId: string,
  modals: ModalsState,
  selectedFile: any,
  selectedIssue: any,
  uploadErrors: any[],
}

export class EmailTemplateComponent extends React.Component<any, EmailTemplateState> {
  public static contextType = RasaContext

  private datasetName: string = 'htmlTemplates'
  public constructor(props: any) {
    super(props)
    this.state = {
      communityId: null,
      documentation: {},
      emailAddress: null,
      emailLayoutId: null,
      emailTemplateId: null,
      fileId: null,
      fileName: null,
      forceReload: false,
      hasFeatureAccess: false,
      issues: [],
      isIssuesLoading: false,
      isLoading: false,
      isLoadingPreview: false,
      isSuperUser: false,
      modals: {
        download: {
          visible: false,
        },
        preview: {
          visible: false,
        },
        template: {
          visible: false,
        },
      },
      publishedId: null,
      selectedFile: null,
      selectedIssue: {key: 'No Issue', value: -1},
      uploadErrors: [],
    }
    this.hideModal.bind(this)
    this.showModal.bind(this)
  }

  public componentDidMount = () => {
    this.context.user.init().then(({person, activeCommunity}) => {
      const avlFeatures: BillingPlanDetailCode[] = activeCommunity.billingInfo.currentPlan.features || []
      this.setState({
        hasFeatureAccess: avlFeatures.indexOf(BillingPlanDetailCode.EMAIL_TEMPLATES) > -1,
        emailAddress: person.email,
        communityId: activeCommunity.communityId,
        emailLayoutId: activeCommunity.communityInfo.data.email_layouts.filter((s: any) => s.is_active)[0].id,
        isSuperUser: activeCommunity.role === Roles.super_admin,
      })
      this.loadIssues(activeCommunity.communityId)
      this.fetchTemplateDocumentation()
    })
  }

  public render() {
    return this.state.hasFeatureAccess ?
    <div className="email-templates">
      <div className="header-container">
        <HeaderComponent
          icon="emailSent"
          title={'Email Templates'}
          subTitle={'Update your HTML template'}
          description={[
            'Add a new template, or update one of your existing templates',
          ]}
        />
        <div className="add-new-template-wrapper">
          <Button className="add-button" onClick={() => this.showModal('template')}>
            <span className="button-text">Add Template</span>
          </Button>
        </div>
      </div>
      <div className="body">
        <RasaDataGrid
            entityName={this.datasetName}
            datasetName={this.datasetName}
            editDefaultState={false}
            gridTransform={this.gridTransform}
            sortable={true}
            pageable={false}
            data={[]}
            forceReload={this.state.forceReload}>
          <GridColumn field="published" cell={PublishedCell} title="Current" width={100}/>
          <GridColumn field="fileName" title="Name"/>
          <GridColumn field="preview" cell={this.PreviewCell} title="Preview"/>
          <GridColumn field="lastModified" cell={DateTimeCell} title="Last Modified"/>
          {this.state.isSuperUser &&
          <GridColumn field="path" title="S3 Path"/>}
          <GridColumn cell={this.EditCell} width={50}/>
        </RasaDataGrid>
        { this.state.documentation.attributes ?
        <div className="documentation">
          <h1>Custom HTML Documentation</h1>
          Custom HTML can be built using attributes as defined below to provide processing instructions
          for the insertion of dynamic, personalized content.
          <h2>Modules</h2>
          Specify a block of HTML to be replaced with a single dynamic entity (such as an article).
          {this.renderDocumentationModules()}
          <h3>Module Parameters</h3>
          These parameters can be specified on any element containing a data-rasa-module attribute.
          {this.renderDocumentationParameters('module')}
          <h2>Attributes</h2>
          Specify the treatment of specific data points by using these attributes on HTML elements.
          {Object.keys(this.state.documentation.attributes)
            .map((key: string) => this.renderDocumentationAttributes(key))}
          <h3>Attribute Parameters</h3>
          These parameters can be specified on any element containing a data-rasa-attribute attribute.
          {this.renderDocumentationParameters('attribute')}
        </div> : null }
      </div>
      {this.renderTemplateModal()}
      {this.renderDownloadModal()}
      {this.renderPreviewModal()}
    </div> :
    <FeatureUnavailableComponent source={GA.UpgradeSource.CustomTemplate}  />
  }

  private gridTransform = (input: any): any => {
    if ( this.state.publishedId ) {
      input.published = ( input.id === this.state.publishedId )
    }
    return input

  }

  private renderDocumentationModules = () => {
    return <div className="module">
      <ul>
        {this.state.documentation.modules.map((attr: any) =>
          this.renderDocumentationAttribute(attr))}
      </ul>
    </div>

  }

  private renderDocumentationParameters = (parameterType) => {
    return <div className="parameter">
      <ul>
      {this.state.documentation.parameters[parameterType].map((attr: any) =>
        this.renderDocumentationAttribute(attr))}
      </ul>
    </div>
  }

  private renderDocumentationAttributes = (key: string) => {
    return <div key={key} className="attribute">
      <strong>{key}</strong>
      <ul>
      {this.state.documentation.attributes[key].map((attr: any) =>
        this.renderDocumentationAttribute(attr))}
      </ul>
    </div>
  }

  private renderDocumentationAttribute = (attr: any) => {
    return <li key={attr.key}>
      <em>{attr.key}</em>: {attr.description}
      { attr.example ?
      <span><br/><code>{attr.example}</code></span> : null}
    </li>
  }

  private PreviewCell = (props: EditCellProps) => {
    return <td>
      <span>
        <Button disabled={this.state.isLoadingPreview}
                onClick={() => this.generateEmailTemplatePreview(props.dataItem.id)}>
           Preview
        </Button>
      </span>
    </td>
  }

  private renderPreviewModal() {
    return <Modal isOpen={this.modalOpen('preview')}
             toggle={() => this.hideModal('preview')}
             size="lg"
             className="template-preview-modal"
             fade={false}
             centered={true} >
        <ModalHeader toggle={() => this.hideModal('preview')}>
          <span>Preview</span>
          <div className="header-wrapper">
            <Input
                value={this.state.emailAddress}
                placeholder="Enter email..."
                onChange={(e) => {
                  e.preventDefault()
                  this.setState({ emailAddress: e.target.value })
                  this.props.onChange({
                    email: e.target.value,
                  })
                }}
              />
              {this.state.issues.length
                ? <div className="issue-selector">
                    <div className="issues-dropdown">
                      {this.state.issues.length &&
                      <DropdownComponent
                        data={this.state.issues}
                        selected={this.state.selectedIssue.key}
                        onChange={this.issueChanged}/>}
                    </div>
                  </div>
                : null}
              <Button disabled={this.state.isLoadingPreview || isEmpty(this.state.emailAddress)}
                      onClick={() => this.generateEmailTemplatePreview(this.state.emailTemplateId, true)}>
                Send a Sample
              </Button>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="preview">
            {this.state.isLoadingPreview
              ? <Loading size="32"></Loading>
              : <div dangerouslySetInnerHTML={{
                  __html: this.state.htmlPreview,
                }}/>
            }
          </div>
        </ModalBody>
      </Modal>
  }

  private renderDownloadModal() {
    return  <Modal isOpen={this.modalOpen('download')}
             toggle={() => this.hideModal('download')}
             size="lg"
             fade={false}
             centered={true} >
        <ModalHeader toggle={() => this.hideModal('download')}>Select Download Type</ModalHeader>
        <ModalBody>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => this.downloadEmailTemplate('')}>
            Original
          </Button>
          <Button onClick={() => this.downloadEmailTemplate(':compiled')}>
            Compiled
          </Button>
          <Button onClick={() => this.downloadEmailTemplate(':template')}>
            Template
          </Button>
        </ModalFooter>
      </Modal>
  }

  private renderTemplateModal() {
    return <Modal isOpen={this.modalOpen('template')}
             toggle={() => this.hideModal('template')}
             className="settings-account-billing-modal"
             size="lg"
             fade={false}
             centered={true} >
        <ModalHeader toggle={() => this.hideModal('template')}>Upload HTML Template</ModalHeader>
        <ModalBody>
          <div className="template-upload-errors">
            {this.state.uploadErrors.map((x) => {
              return <div>Error at line {x.line }: {x.message}</div>
            })}
          </div>
          <FileSelector fileType="html" onSelect={(e) => this.onFileSelect(e)} />
          {this.state.uploadErrors && this.state.uploadErrors.length
          ? <div>
            <div>Your Custom HTML shows errors. Do you still want to upload with errors?</div>
            <div>${BRAND_NAME} is not responsible for unexpected template results.</div>
            <Button disabled={isNil(this.state.selectedFile)}
                onClick={() => this.uploadEmailTemplate(true)}>
              Upload Anyway
            </Button>
           </div>
          : null
          }
        </ModalBody>
        <ModalFooter>
          {this.state.isLoading ? <Loading size="32"></Loading> : null}
          <Button disabled={this.state.isLoading || isNil(this.state.selectedFile)}
                  onClick={() => this.uploadEmailTemplate(false)}>
            Upload
          </Button>
        </ModalFooter>
      </Modal>
  }

  private EditCell = (props: EditCellProps) => {
    return <EditableDropdownCell {...props}
              onEdit={this.onSelectUpdate}
              onDownload={this.onSelectDownload}
              onSave={this.onPublish}
              editLabel="Update"
              saveLabel="Publish"
              canSave={true}
              canEdit={true}
              canDownload={true}
              canArchive={props.dataItem.published ? false : true} />
  }

  private onPublish = (dataItem: any) => {
    this.setState({
      isLoading: true,
      publishedId: dataItem.id,
    }, () => {
      this.context.entityMetadata.getEntityObject('email_layout').then(
        (entityObject: BaseClientEntity) => {
          entityObject.setRecordId(this.state.communityId, this.state.emailLayoutId)
          entityObject.data[EmailAttributes.emailTemplate] = `custom:${dataItem.id}`
          entityObject.save()
          .then(() => {
            refreshCommunityConfigurations(this.state.communityId)
            .then(() => {
              this.setState({ isLoading: false })
            })
          })
        })
    })
  }

  private onSelectDownload = (dataItem: any) => {
    this.setState({
      fileId: dataItem.id,
      fileName: dataItem.fileName,
    }, () => {
      if ( this.state.isSuperUser ) {
        this.showModal('download')
      } else {
        this.downloadEmailTemplate('')
      }
    })
  }

  private onSelectUpdate = (dataItem: any) => {
    this.setState({
      fileId: dataItem.id,
      fileName: dataItem.fileName,
    }, () => this.showModal('template'))
  }

  private onFileSelect(f: any) {
    if (f) {
      this.setState({
        selectedFile: f,
      })
    }
  }

  private showModal(modal: string) {
    this.setState({
      isLoading: false,
      modals: {
        ...this.state.modals,
        [modal]: {
          ...this.state.modals[modal],
          visible: true,
        },
      },
    })
  }

  private hideModal(modal: string) {
    this.setState({
      fileId: null,
      fileName: null,
      uploadErrors: [],
      modals: {
        ...this.state.modals,
        [modal]: {
          ...this.state.modals[modal],
          visible: false,
        },
      },
    })
  }

  private modalOpen(modal: string) {
    return this.state.modals[modal].visible
  }

  private fetchTemplateDocumentation() {
    const url: string = `${AjaxWrapper.getServerUrl()}/email/documentation`
    return AjaxWrapper.ajax(url, HttpMethod.GET, {})
      .then((response) => {
        this.setState({
          documentation: response,
        })
      })
  }

  private generateEmailTemplatePreview(templateId: any, sendEmail: boolean = false) {
    this.showModal('preview')
    this.setState({
      isLoadingPreview: true,
    }, () => {
      let url: string = `${AjaxWrapper.getServerUrl()}/email/preview/${this.state.communityId}/${templateId}`
      if ( this.state.emailAddress && sendEmail) {
        url = `${url}?email=${this.state.emailAddress}`
      }

      loadSampleArticles().then((contentList) => {
        const payload: any = animalsTestingPayload(contentList)
        this.emailPreviewRequest(url, templateId, payload);
      })
    })

  }

  private emailPreviewRequest = (url: string, templateId: any, payload: any) => {
    if (this.state.selectedIssue && this.state.selectedIssue.value > -2) {
      if (this.state.selectedIssue.value > 0) {
        payload.issueId = this.state.selectedIssue.value
      }
      delete payload.article
    }
    AjaxWrapper.ajax(url, HttpMethod.POST, payload)
      .then((response) => {
        this.setState({
          emailTemplateId: templateId,
          htmlPreview: response.data.body,
          isLoadingPreview: false,
        })
      })
      .catch((error) => {
        this.setState({
          htmlPreview: error.message,
          isLoadingPreview: false,
        })
      })
  }
  private downloadEmailTemplate(templateType: string) {
    this.setState({
      isLoading: true,
    }, () => {
      const timestamp: string = format(new Date(), 'yyyyMMddHHmmss')
      const downloadFileName: string = `${this.state.fileName.replace('.html', '')}_${templateType}_${timestamp}.html`
      this.context.entityMetadata.getEntityObject(this.datasetName, this.state.communityId, `${this.state.fileId}${templateType}`)
        .then((e) => GenericUtils.downloadTextFile(e.data.body, 'html', downloadFileName))
    })
  }

  private uploadEmailTemplate(forceUpload) {
    this.setState({
      isLoading: true,
    }, () => {
      this.context.entityMetadata.getEntityObject(this.datasetName)
      .then((entityObject: BaseClientEntity) => {
        entityObject.setRecordId(this.state.communityId, this.state.fileId)
        const uploadOptions = {
          forceUpload,
        }
        return entityObject.upload('html', this.state.selectedFile, uploadOptions)
          .then(() => {
            this.context.store.dispatch(Flash.showFlashMessage('Template has been uploaded and saved'))
            this.setState({
              forceReload: true,
            }, () => {
              this.setState({forceReload: false})
            })
            this.hideModal('template')
          })
          .catch((err) => {
            const errResponse = err.response && err.response.error && err.response.error.replace ?
              GenericUtils.tryParseJson(err.response.error.replace('Error: ', '')) :
              err.response && err.response.message && err.response.message.replace ?
              GenericUtils.tryParseJson(err.response.message.replace('Error: ', '')) : {}
            if (errResponse.errors) {
              this.setState({
                isLoading: false,
                uploadErrors: errResponse.errors || [],
              })
            } else {
              this.context.store.dispatch(Flash.showFlashError(errResponse.message || 'Unable to save HTML'))
              this.hideModal('template')
            }
          })
      })
    })
  }

  private loadIssues = (communityId) => {
    return new Dataset().loadCommunityDataset('communityIssues', communityId, null, 10)
      .then((issues) => {
        const allIssuesData = issues[0]
          .filter((i) => i.content_pool_size > 0)
          .map((i) => {
            const d: Date = new Date(i.send_at_in_timezone)
            const dateWithoutOffset = d.setMinutes(d.getMinutes() + d.getTimezoneOffset())
            const key = format(new Date(dateWithoutOffset), 'iiii, MMM d H:mm')
            return {
              description: key,
              key,
              value: i.id,
            }
          }).slice(0, 5)
        const issueDataForFilter = allIssuesData.length ? [
          {
            key: 'Sample Content',
            divider: false,
            value: '-2',
          },
          {
            key: 'Current Content',
            divider: false,
            value: '-1',
          },
          ...allIssuesData,
        ] : []

        this.setState({
          isIssuesLoading: false,
          selectedIssue: issueDataForFilter[0],
          issues: issueDataForFilter,
        })
    })
  }
  private issueChanged = (e: any) => {
    this.setState({selectedIssue: e.selected})
    this.generateEmailTemplatePreview(this.state.emailTemplateId)
  }
}

const animalsTestingPayload = (contentList: string[]): any => {
  const extraAnimals = shuffle(contentList).slice(0, 10)
  return {
    article: extraAnimals.map((sampleArticle: any) => toTemplateArticle(sampleArticle)),
    footer: [{
      date: new Date(),
      image_url: `${RASA_IMAGE_API_ENDPOINT}/hosted-images-newsbrief/path/rasa/rasa_io-logo-white_outline-words.png?w=200&h=80`,
      subscribe_url: 'https://pages.rasa.io/signup/rasa?utm_source=newsbrief',
      unsubscribe_url: 'https://pages.rasa.io/unsubscribe/rasa?guid=cbb0c7b6-4150-4623-b2d7-d9fe062dc28f',
      url: 'https://rasa.io/',
    }],
    header: [{
      date: new Date(),
      first_name: 'John',
      image_url: 'https://rasa-logos.s3.amazonaws.com/logos/rasa/1583282986.3373458/pushingsend-border3-19.png',
      last_name: 'Doe',
      subscribe_url: 'https://pages.rasa.io/signup/rasa?utm_source=newsbrief',
      unsubscribe_url: 'https://pages.rasa.io/unsubscribe/rasa?guid=cbb0c7b6-4150-4623-b2d7-d9fe062dc28f',
      url: 'https://rasa.io/',
    }],
    html: [
      {
        description: '<div>This is sample <strong>Text</strong>.</div>',
        type: 'Text1',
      },
      {
        description: '<div>This is sample <strong>Text 2</strong>.</div>',
        type: 'Text2',
      },
    ],
    image: [
      {
        url: 'https://rasa.io/',
        image_url:  `${RASA_IMAGE_API_ENDPOINT}/self-service-logos/path/2021-06-02/files/c3f0b3d2-6a7f-43aa-8ac2-b33e3e51f500?fit=max&w=600`,
        title: 'Test Image Title',
        type: 'Banner 1',
      },
    ],
  }
}

const toTemplateArticle = (sampleArticle) => {
  return {
    description: sampleArticle.description,
    image_url: sampleArticle.hosted_image_url,
    source: sampleArticle.site_name,
    title: sampleArticle.title,
    url: sampleArticle.url,
  }
}
