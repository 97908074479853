import { UpgradeAnchorLink } from 'components/link'
import { Loading } from 'components/loading'
import { EnterpriseRefComponent } from 'components/upgrade/enterprise-ref'
import { RasaContext } from 'context'
import { noMoreUpgradeAvailable } from 'generic/utility'
import React, {Component} from 'react'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { UpgradeComponent, UpgradeComponentProps } from '../upgrade/component';
import { ContactToSupportComponent, ContactToSupportComponentProps } from '../upgrade/contact-to-rasa'
import './styles.css'

export enum FeatureUnavailableComponentName {
  AnchorLink,
  Upgrade,
  ContactSupport,
}

export interface FeatureUnavailableProps {
  displayMessage?: string,
  source: string,
  loadComponent?: FeatureUnavailableComponentName,
  upgradeComponentProps?: UpgradeComponentProps,
  contactToSupportProps?: ContactToSupportComponentProps,
}

export interface FeatureUnavailableState {
  loading: boolean,
  activeCommunity: any,
  isNoMoreUpgradeAvailable: boolean,
}

export class FeatureUnavailableComponent extends Component<FeatureUnavailableProps, FeatureUnavailableState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  constructor(props: FeatureUnavailableProps) {
    super(props)
    this.state = {
      loading: true,
      activeCommunity: {},
      isNoMoreUpgradeAvailable: true,
    }
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
    ]).then(([activeCommunity]) => {
      this.setState({
        activeCommunity,
        isNoMoreUpgradeAvailable: noMoreUpgradeAvailable(activeCommunity.billingInfo.currentPlan),
        loading: false,
      })
    })
  }

  public render() {
    return (
      this.state.loading ? <Loading size="64" /> :
      <div className="unavailable-feature">
        {this.getRenderComponent()}
      </div>
    )
  }

  private getRenderComponent = () => {
    let componentToLoad = <EnterpriseRefComponent />
    if (!this.state.isNoMoreUpgradeAvailable) {
      componentToLoad = <UpgradeAnchorLink source={this.props.source}>
        <div className="feature-unavailable">
          {this.props.displayMessage ? this.props.displayMessage : 'Upgrade your plan to access' }
        </div>
      </UpgradeAnchorLink>

      switch (this.props.loadComponent) {
        case FeatureUnavailableComponentName.Upgrade:
          componentToLoad = <UpgradeComponent {...this.props.upgradeComponentProps}/>
          break
        case FeatureUnavailableComponentName.ContactSupport:
          componentToLoad = <ContactToSupportComponent {...this.props.contactToSupportProps} />
          break
      }
    }
    return componentToLoad
  }
}
